async function getDates(fromDate, toDate) {

  return fetch('/api/date?' + new URLSearchParams({
    fromDate: fromDate,
    toDate: toDate,
  })
    ,
    {
      method: 'GET',
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response.json());
    });
}

async function putDate(date, country) {
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify({date: date, country: country}),
  };
  return fetch('/api/date', requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    });
}

async function getStats(year) {
  const requestOptions = {
    method: 'GET',
  };
  return fetch('/api/stats?' + new URLSearchParams({ year: year }), requestOptions)
    .then(response => response.json()
    ).then((data) => {
      return data;
    })
    .catch(error => {
      console.error('There was an error!', error);
      return ":(";
    });
}

export default { getDates, putDate, getStats };