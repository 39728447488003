import React from 'react';
import ReactDOM from 'react-dom/client';

import { MyDates } from './MyDates';

import AuthWrapper from './AuthWrapper';

import {Helmet} from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));

const logoutPasser = { logoutFunc: null };

root.render(
  <div className="root">
    {/** <React.StrictMode> */}
    <Helmet>
      <title>Vardådå</title>
      <link rel="icon" href="/favicon.png" />
    </Helmet>
    <AuthWrapper logout={logoutPasser}>
      <MyDates logout={logoutPasser} />
    </AuthWrapper>
    {/** </React.StrictMode> */}
  </div>
);
