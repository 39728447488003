import React from 'react';

import Navigation from './Navigation'
import API2 from './API';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import './static/bootstrap.min.css'

class MyDates extends React.Component {

  constructor(props) {
    super(props);

    let today = new Date().toISOString().substring(0, 10);

    let start = new Date();
    start.setDate(start.getDate() - 15);

    this.state = {
      dates: [],
      fromDate: start.toISOString().substring(0, 10),
      toDate: today
    };
    this.logout = props.logout;
  }

  componentDidMount() {
    API2.getDates(this.state.fromDate, this.state.toDate).then(d => {
      this.setState({ dates: d });
    });
  }

  dateback(propName) {
    var thiz = this;
    return (value) => {
      let params = {
        fromDate: thiz.state.fromDate,
        toDate: thiz.state.toDate
      };
      params[propName] = value

      API2.getDates(params.fromDate, params.toDate).then(d => {
        thiz.setState({ dates: d, [propName]: value });
      }).catch(e => {
        thiz.setState({ dates: [], [propName]: value, error: e });
      });
    };
  }

  stats(year) {
    var thiz = this;
    return () => {
      API2.getStats(year).then(d => {
        thiz.setState({ stats: d });
      }).catch(e => {
        thiz.setState({ error: e });
      });
    }
  }

  render() {
    let fromDate = this.state.fromDate;
    let toDate = this.state.toDate;

    let daysBefore = 5;

    let newStart = new Date(Date.parse(fromDate));
    newStart.setDate(newStart.getDate() - daysBefore);

    let newEnd = new Date(Date.parse(toDate));
    newEnd.setDate(newEnd.getDate() + daysBefore);

    let stats = (<br />);
    if (this.state.stats != null) {
      stats = generateStats(this.state.stats);
    }

    let dates = this.state.dates.sort((a,b) => -1 * a.date.localeCompare(b.date));

    return (
      <div>
        <Navigation logout={this.logout} />
        <div className="container" style={{ backgroundColor: "#F8F8F8", borderRadius: "5px", padding: "25px" }}>
          <div className='form-group form-row' >
            <button type="button" className={"btn waves-effect waves-light btn-lg btn-outline-dark btn-block"} onClick={this.stats(fromDate.substring(0, 4))}>Statistik</button>
          </div>
          <br />
          {stats}
          <br />
          <div className={"form-row"}>
            <div className='col-md-auto'>
              <p>Från</p>
            </div>
            <div className='col'>
              <input type="date" className={"form-control"} id="fromDate" onChange={v => this.dateback("fromDate")(v.target.value)} value={fromDate} />
            </div>
            <div className='col-md-auto'>
              <p>Till</p>
            </div>
            <div className='col'>
              <input type="date" className={"form-control col"} id="toDate" onChange={v => this.dateback("toDate")(v.target.value)} value={toDate} />
            </div>
          </div>
          <br />
          <h3>Platser</h3>
          <div className='form-group form-row' >
            <button type="button" className={"btn waves-effect waves-light btn-lg btn-outline-dark btn-block"} onClick={v => this.dateback("toDate")(newEnd.toISOString().substring(0, 10))}>Senare</button>
          </div>
          {dateRows(dates, this.putBack())}
          <div className='form-group form-row'>
            <button type="button" className={"btn waves-effect waves-light btn-lg btn-outline-dark btn-block"} onClick={v => this.dateback("fromDate")(newStart.toISOString().substring(0, 10))}>Tidigare</button>
          </div>

        </div>
      </div>
    );
  }

  putBack() {
    var thiz = this;
    return (date, country) => {
      API2.putDate(date, country).then(i => {
        let dates = thiz.state.dates;
        dates = dates.map(d => (d.date === i.date) ? i : d);
        thiz.setState({ dates: dates, stats: null });
      }).catch(e => {
        thiz.setState({ error: e });
      });
    };
  }
}


function dateRows(dates, putBack) {
  return dates.map(d => dateRow(d, putBack));
}

function dateRow(data, putBack) {
  let color = { DK: "#f4cfd5", SE: "#cce1ed", FREE: "#cbcada", SKANE: "#f4cfd5", OST: "#cce1ed"}[data.country];
  let dateColor = "#FFFFFFF";
  return (<div key={data.date} className='form-group form-group-group form-row' style={{ backgroundColor: color }}>
    <div className='col' >
      <h3 style={{backgroundColor: dateColor}}>
        {data.date}    <span style={{fontSize: "15px"}}>{weekDay(data.dayOfWeek)}</span>
      </h3>
    </div>
    <div className='col'>
      <h4>{niceName(data.country)}</h4>
    </div>
    <div className='col-md-auto'>
      <button type="button" className={"btn waves-effect waves-light btn-lg btn-danger"} style={{ color: "#ffff00" }} onClick={() => putBack(data.date, "SKANE")}><b>Skåne</b></button>
    </div>
    <div className='col-md-auto'>
      <button type="button" className={"btn waves-effect waves-light btn-lg btn-primary"} style={{ color: "#ffff00" }} onClick={() => putBack(data.date, "OST")}><b>Östergötland</b></button>
    </div>
  </div>);
}

function niceName(place){
  switch(place){
    case "OST":
      return "Östergötland";
    case "SKANE":
      return "Skåne";
    case null:
      return "";
  }
}

function weekDay(day){
  switch(day){
    case 1:
      return "Måndag";
    case 2:
      return "Tisdag";
    case 3:
      return "Onsdag";
    case 4:
      return "Torsdag";
    case 5:
      return "Fredag";
    case 6:
      return "(Lördag)";
    case 7:
      return "(Söndag)";
  }
}


function generateStats(stats) {
  return (<div className='form-group-group'>
    <div>
    <p>Års ställning: {(100 * stats.year.days.SKANE / (stats.year.days.SKANE + stats.year.days.OST)).toFixed(0)}% dagar i skåne (Orapporterade dagar: {stats.year.notSelectedDays})</p>
    </div>
  </div>);
}

export { MyDates };